





















































import { Vue, Component } from 'vue-property-decorator'
import { Form as ElForm } from 'element-ui/types/element-ui'
import { readFile } from '@/utils/readfile'
import { queryAlgorithmDetail, uploadAlgorithm } from '@/api/algorithm'

@Component
export default class AlgorithmManagementDetail extends Vue {
  private form: any = {
    fileList: []
  }

  private dialogImageUrl = ''
  private dialogVisible = false
  private uploading = false

  private rules = {
    app_name: [{ required: true, message: '请填写应用名称' }],
    state: [{ required: true, message: '请选择应用状态' }]
  }

  private get readonly() {
    return this.$route.query.type == 'view'
  }

  private mounted() {
    this.query_algorithm()
  }

  private async query_algorithm() {
    const {
      algo_id
    } = this.$route.query
    if (!algo_id) {
      this.form = {
        state: true
      }
      return
    }
    const { data } = await queryAlgorithmDetail(algo_id as string)
    this.form = {
      algo_id: data.id,
      state: data.state,
      algo_name: data.algo_name,
      params: data.params,
      create_time: data.create_time,
      remark: data.remark,
      update_data: ''
    }
  }
  private handleChange(file: any, fileList: any) {
    this.baseParams(file)
  }

  private baseParams(file: any) {
    this.uploading = true
    const callback = async (data: any) => {
      this.form.params = data
    }
    readFile(file.raw, callback)
  }

  private handleRemove(file: any, fileList: any) {
    this.form.fileList = fileList
    this.$delete(this.form, 'fileList')
    this.$delete(this.form, 'params')
    this.$set(this.form, 'fileList', fileList)
    this.form.params_name = ''
  }

  private handleSave() {
    ;(this.$refs.form as ElForm).validate(async (valid: boolean) => {
      if (valid) {
        try {
          const {
            algo_id
          } = this.$route.query
          const { params, state, remark, update_data, algo_name } = this.form
          const request_params = { params, state, remark, update_data, algo_name }
          await uploadAlgorithm(request_params, algo_id as string)
          this.$message.warning('保存成功')
          this.goBack()
        } catch (e) {
          console.warn(e, 'login')
        }
      } else {
        return false
      }
    })
  }

  private goBack() {
    this.$router.back()
  }
}
